import React from "react"
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'

export default function Home({ data }) {
  return (
    <Layout title="Home">
      <div>
        <ReactMarkdown source={data.markdownRemark.frontmatter.description} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query HomeQuery {
  markdownRemark(frontmatter: {title: {eq: "Home"}}) {
    frontmatter {
      header
      description
    }
  }
}
`
